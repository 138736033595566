export const navItem = [
    {
        label: "Home",
    },
    {
        label:"Activity",
    },
    {
        label:"Classes",
    },
    {
        label:"Blogs",
    },
    {
        label:"About"
    },
    {
        label:"Contact"
    }
]


export const youtubeList = [
    {
        src: "https://www.youtube.com/embed/JXVIpqAwu_M?si=a2GODoPBEfhSJMQl",
        title:"Green Hackers, How to Study IT"
    },
    {
        src: "https://www.youtube.com/embed/videoseries?si=_p7JTotfyBNFs_0p&amp;list=PLkNqzR4g-0Ej8h-GGgN_xu97olOrcrOas",
        title:"Green Hackers, Study Code for KIDs"
    },
    {
        src: "https://www.youtube.com/embed/videoseries?si=cFGvIZ56KuL_cLai&amp;list=PLkNqzR4g-0Ei5x-JSIDenni36o8enb5Z9",
        title:"Green Hackers, What and How is Programming"
    },
    {
        src: "https://www.youtube.com/embed/videoseries?si=ChA_B2Hh49Mj92G0&amp;list=PLkNqzR4g-0Ej7YwzQPjwZMLstxUcl3hsc",
        title:"Green Hackers, Create Virtual Machine in C"
    }
]