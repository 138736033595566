import Footer from "./pages/Footer";
import HomePage from "./pages/HomePage";
import NavBar from "./pages/NavBar";

function App() {
  return (
    <div className="bg-white dark:bg-slate-800 w-full font-Poppins justify-center">
      <NavBar />
      <HomePage />
      <Footer/>
    </div>
  );
}

export default App;
