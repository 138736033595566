import React from 'react'
import Logo from "../images/logo.jpg";


const Footer = () => {
  return (
    <div className='p-16 flex-1 flex-row text-white'>
        <img src={Logo} alt="Green Hackers" className='w-20 h-auto rounded-xl'/>
        <div>
            Github
        </div>
    </div>
  )
}

export default Footer