import React from "react";
import Logo from "../images/logo.jpg";
import lesson from "../images/lesson.png";
import profile from "../images/profile.jpg";
import { navItem } from "../constants";
import { FaSearch, FaAddressBook } from "react-icons/fa";

const NavBar = () => {
  return (
    <div className="flex-col">
      <div className="flex flex-row items-center justify-between dark:text-gray-300 ">
        <div className="px-10 py-4">
          <img
            src={Logo}
            alt="GreenHackers Logo"
            className="h-12 w-12 rounded-full bg-white p-1"
          />
        </div>
        <div className="flex flex-row">
          {navItem.map((item) => {
            return <div className="px-4 py-2 cursor-pointer">{item.label}</div>;
          })}
        </div>
        <div className="flex flex-row">
          <FaSearch className="mx-2" />
          <FaAddressBook className="mx-2" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row px-10 py-4 items-center justify-center mt-8 relative">
        <div className="w-full md:w-1/2 text-center items-center justify-center flex-col  rounded-md z-10 sm:mt-14">
          <div className="text-7xl font-bold items-center justify-center tracking-wide text-white p-4">
            Start Learning Everything for Everyone
          </div>
          <div className="text-gray-300 mt-10 text-2xl">unity is power</div>
          <div className="px-10 rounded-md py-3 my-12 w-52 bg-green-500 text-white cursor-pointer shadow-2xl text-xl inline-block hover:bg-green-400">
            Get Starts
          </div>
        </div>

        <div className="sm:flex-col md:flex-1  hidden md:block">
          <img
            src={lesson}
            alt="logo Icon"
            className="shadow-2xl absolute bottom-0 right-10 h-80 w-auto rounded-lg z-30 hidden md:block rotate-6"
          />
          <img
            src={lesson}
            alt="Profile"
            className="rounded-lg shadow-2xl opacity-75 -rotate-12 h-80 w-auto z-20 md:ml-10"
          />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
