import React from "react";
import Bio from "../images/cv.png";
import Logo from "../images/logo.jpg";
import Plane from "../images/plane.jpg";
import { youtubeList } from "../constants";

const HomePage = () => {
  return (
    <div className="flex-1 flex-row flex-wrap w-full mt-20 justify-center items-center">
      <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-100 px-40 py-20">
        Welcome to Green Hackers Youtube Channel!
      </h1>
      <div className="flex flex-row flex-wrap justify-center items-center gap-24">
        {youtubeList.map((item) => (
          <iframe
            className="rounded-xl shadow-2xl"
            width="560"
            height="315"
            src={item.src}
            title={item.title}
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        ))}
      </div>
      <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-100 px-40 pt-20">
        About Me
      </h1>
      <div className="flex flex-row justify-center py-8 px-4">
        <div className="shadow-2xl rounded-3xl border-2 border-gray-600">
          <img
            src={Bio}
            alt="Dr.AungWinHtut, GreenHackers Founder"
            className="object-fit rounded-3xl"
          />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
